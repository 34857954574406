import { BLEED_DIRECTIONS } from '../constants/apiConstants'

const biDesktop = [
  [66, 4, 5, 1413],
  [67, 5, 6, 0],
  [68, 6, 8, 1325],
  [69, 2, 3, 2064],
  [70, 4, 7, 1733],
  [71, 2, 3, 2724],
  [72, 4, 6, 3246, true],
  [73, 6, 7, 3246, true, BLEED_DIRECTIONS.LEFT],
  [74, 6, 8, 2412],
  [75, 2, 5, 3374],
  [76, 3, 4, 3708],
  [77, 5, 6, 3881],
  [78, 6, 8, 3374],
  [79, 3, 4, 4386],
  [80, 4, 5, 4202],
  [81, 6, 8, 4125],
  [82, 2, 3, 5168],
  [83, 4, 6, 4863],
  [84, 6, 7, 5057],
  [85, 6, 8, 4600],
  [86, 8, 9, 4600, false, BLEED_DIRECTIONS.LEFT],
  [87, 3, 5, 5587],
  [88, 5, 7, 5405],
  [89, 6, 7, 5709],
  [90, 7, 9, 5709],
  [91, 2, 5, 6378, true],
  [92, 5, 7, 6378, true, BLEED_DIRECTIONS.LEFT],
  [93, 6, 7, 6524],
  [94, 7, 8, 6435],
  [95, 2, 3, 6744],
  [96, 3, 4, 6982],
  [97, 5, 6, 6982],
  [98, 4, 5.5, 6884, true],
  [99, 5.5, 7, 6884, true],
  [100, 2, 5, 7677],
  [101, 6, 7, 7755],
  [102, 7, 8, 8005],
  [103, 4, 6, 8424],
  [104, 6, 8, 8631],
  [105, 4, 5, 9012],
  [106, 2, 3, 8865],
  [107, 2, 3, 9435],
  [108, 3, 3.5, 9640],
  [109, 7, 8, 9328],
  [110, 8, 9, 9411],
  [111, 3, 4, 9950],
  [112, 4, 5, 9845],
  [113, 4, 5, 10222],
  [114, 5, 8, 9877],
  [115, 2, 4, 10539],
  [116, 4, 5, 10886],
  [117, 7, 9, 10830],
  [118, 5, 6, 10536],
  [119, 6, 7, 10597],
  [120, 3, 5, 11204],
  [121, 3, 4, 11623],
  [122, 5, 6, 11294, undefined, BLEED_DIRECTIONS.LEFT],
  [123, 6, 7, 11492],
  [124, 7, 8, 11271],
  [125, 2, 3, 11929],
  [126, 5, 7, 12166],
  [127, 6, 8, 11859],
]

const smDesktop = [
  [170, 3, 5, 0],
  [171, 6, 8, 1265],
  [172, 3, 4, 2001],
  [173, 6, 7, 1794],
  [174, 7, 8, 1913],
  [175, 3, 4, 2549],
  [176, 4, 5, 2306, false, BLEED_DIRECTIONS.RIGHT],
  [177, 5, 8, 2306],
  [178, 2, 3, 3199],
  [179, 4, 6, 2881],
  [180, 6, 7, 2881],
  [181, 7, 8, 3065],
  [182, 2, 4, 3504],
  [183, 4, 5, 4157],
  [184, 4, 6, 3668],
  [185, 7, 8, 3587],
  [186, 7, 8, 4275],
  [187, 2, 5, 4582],
  [188, 6, 7, 4651],
  [189, 7, 9, 4910],
  [190, 2, 4, 5328],
  [191, 5, 8, 5419],
  [192, 3, 4, 6347],
  [193, 4, 5, 6010],
  [194, 5, 6, 6010],
  [195, 6, 7, 6362],
  [196, 7, 9, 6172],
  [197, 2, 3, 7130],
  [198, 5, 8, 6699],
  [199, 2, 4, 7435],
  [200, 4, 5, 8064, true],
  [201, 5, 6, 8064, true, BLEED_DIRECTIONS.LEFT],
  [202, 6, 7, 7596],
  [203, 3, 5, 8187],
  [204, 5, 6, 8790, true],
  [205, 6, 7, 8790, true, BLEED_DIRECTIONS.LEFT],
  [206, 7, 8, 8227],
  [207, 3, 4, 9092],
  [208, 4, 5, 9250],
  [209, 5, 7, 8913],
  [210, 7, 8, 8913, false, BLEED_DIRECTIONS.LEFT],
  [211, 4, 6, 9579],
  [212, 7, 8, 10143],
  [213, 7, 8, 9600],
  [214, 8, 9, 9867],
  [215, 2, 3, 10777],
  [216, 3, 4, 10466],
  [217, 4, 6, 10830],
  [218, 6, 7, 10755],
  [219, 6, 8, 10379],
  [220, 2, 3, 11495],
  [221, 4, 5, 11180],
  [222, 5, 7, 11437],
  [223, 7, 8, 11205],
  [224, 2, 5, 11942],
  [225, 6, 7, 12612],
  [226, 6, 7, 12017],
  [227, 7, 9, 12140],
]

const biMobile = [
  [66, 2, 3, 2350],
  [67, 3, 4, 0],
  [68, 4, 6, 2308],
  [69, 1, 2, 2891],
  [70, 2, 5, 2545],
  [71, 1, 2, 3176],
  [72, 2, 4, 3405, true,],
  [73, 4, 5, 3405, true, BLEED_DIRECTIONS.LEFT],
  [74, 4, 6, 3042],
  [75, 1, 4, 3519],
  [76, 2, 3, 3664],
  [77, 4, 5, 3736],
  [78, 4, 6, 3519],
  [79, 1, 2, 3958],
  [80, 2, 3, 3877],
  [81, 4, 6, 3843],
  [82, 1, 2, 4338],
  [83, 1, 3, 4207],
  [84, 3, 4, 4291],
  [85, 3, 5, 4095],
  [86, 5, 6, 4095, false, BLEED_DIRECTIONS.LEFT],
  [87, 2, 4, 4518],
  [88, 4, 6, 4440],
  [89, 3, 4, 4738, true],
  [90, 4, 6, 4738, true],
  [91, 1, 4, 5024, true],
  [92, 4, 6, 5024, true, BLEED_DIRECTIONS.LEFT],
  [93, 4, 5, 5085],
  [94, 5, 6, 5047],
  [95, 1, 2, 5282],
  [96, 2, 3, 5342],
  [97, 3, 4, 5342],
  [98, 2, 3.5, 5234, true],
  [99, 3.5, 5, 5234, true],
  [100, 1, 4, 5661],
  [101, 4, 5, 5674],
  [102, 5, 6, 5777],
  [103, 2, 4, 6034],
  [104, 4, 6, 6133],
  [105, 3, 4, 6462],
  [106, 1, 2, 6400],
  [107, 1, 2, 6690],
  [108, 2, 2.5, 6775],
  [109, 4, 5, 6644],
  [110, 5, 6, 6680],
  [111, 2, 3, 6905],
  [112, 3, 4, 6861],
  [113, 2, 3, 7175],
  [114, 3, 6, 7030],
  [115, 1, 3, 7509],
  [116, 2, 3, 7697],
  [117, 4, 6, 7673],
  [118, 3, 4, 7307],
  [119, 4, 5, 7332],
  [120, 1, 3, 7877],
  [121, 2, 3, 8061],
  [122, 3, 4, 7916, false, BLEED_DIRECTIONS.LEFT],
  [123, 4, 5, 8003],
  [124, 5, 6, 7906],
  [125, 1, 2, 8195],
  [126, 3, 5, 8300],
  [127, 4, 6, 8164],
]

const smMobile = [
  [170, 1, 3, 0],
  [171, 4, 6, 2005],
  [172, 1, 2, 2327],
  [173, 4, 5, 2237],
  [174, 5, 6, 2288],
  [175, 1, 2, 2565],
  [176, 2, 3, 2459, false, BLEED_DIRECTIONS.RIGHT],
  [177, 3, 6, 2459],
  [178, 1, 2, 2935],
  [179, 2, 4, 2710],
  [180, 4, 5, 2710],
  [181, 5, 6, 2790],
  [182, 1, 3, 3068],
  [183, 3, 4, 3353],
  [184, 3, 5, 3140],
  [185, 5, 6, 3104],
  [186, 5, 6, 3404],
  [187, 1, 4, 3600],
  [188, 4, 5, 3629],
  [189, 4, 6, 3988],
  [190, 1, 3, 4172],
  [191, 3, 6, 4212],
  [192, 1, 2, 4616],
  [193, 2, 3, 4470],
  [194, 3, 4, 4470],
  [195, 3, 4, 4623],
  [196, 4, 6, 4540],
  [197, 1, 2, 4958],
  [198, 3, 6, 4770],
  [199, 1, 3, 5088],
  [200, 3, 4, 5365, true,],
  [201, 4, 5, 5365, true, BLEED_DIRECTIONS.LEFT],
  [202, 5, 6, 5160],
  [203, 1, 3, 5418],
  [204, 3, 4, 5681, true,],
  [205, 4, 5, 5681, true, BLEED_DIRECTIONS.LEFT],
  [206, 5, 6, 5436],
  [207, 1, 2, 5813],
  [208, 2, 3, 5882],
  [209, 3, 5, 5735],
  [210, 5, 6, 5735, false, BLEED_DIRECTIONS.LEFT],
  [211, 2, 4, 6025],
  [212, 4, 5, 6499],
  [213, 5, 6, 6034],
  [214, 5, 6, 6318],
  [215, 1, 2, 6775],
  [216, 1, 2, 6640],
  [217, 3, 5, 6799],
  [218, 5, 6, 6766],
  [219, 4, 6, 6600],
  [220, 1, 2, 7272],
  [221, 3, 4, 6951],
  [222, 3, 5, 7249],
  [223, 5, 6, 7146],
  [224, 1, 4, 7467],
  [225, 3, 4, 8026],
  [226, 5, 6, 7500],
  [227, 4, 6, 7820],
]

export default {
  blueInsights: {
    desktop: biDesktop,
    mobile: biMobile
  },
  surfaceManipulation: {
    desktop: smDesktop,
    mobile: smMobile
  }
}